import React from "react"
import * as Style from "./InitiativeListItem.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Markdown from "react-markdown"

const InitiativeListItem = data => {
  return (
    <Link to={`/initiatives/${data.data.slug}`}>
      <div className={Style.initiativeWrapper}>
        {data?.data?.area_bg && (
          <div className={Style.area}>{`${
            data?.data?.post_code !== 6300 ? "с." : "гр."
          } ${data?.data?.area_bg}`}</div>
        )}
        <div className={Style.headingWrapper}>
          <Markdown children={data.data.haeding.data.haeding} />
        </div>

        <div className={Style.imageWrapper}>
          <GatsbyImage
            image={
              data.data.heading_media[0].localFile.childImageSharp
                .gatsbyImageData
            }
            alt="heading_image"
          />
        </div>
      </div>
      <p className={Style.learnMorePar}>Натиснете за да Гласувате</p>
    </Link>
  )
}

export default InitiativeListItem
