import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as Style from "./initiatives.module.scss"
import InitiativeListItem from "../components/initiative-list-item/InitiativeListItem"
import DropDown from "../components/UI/drop-down/DropDown"
import Layout from "../components/layout/Layout"
import { Context } from "../context/SiteProvider"
import { navigate } from "gatsby"

const query = graphql`
  query {
    allStrapiInitiative {
      nodes {
        slug
        strapi_id
        id
        area_en
        haeding {
          data {
            haeding
          }
        }
        heading_media {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 300
                height: 300
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }

    allStrapiArea {
      nodes {
        id
        area_bg
        area_en
        post_code
      }
    }
  }
`
const initialPlaceholder = "Изберете населено място"
const Initiatives = () => {
  useEffect(() => navigate("/"))

  const data = useStaticQuery(query)
  const initiativesData = data.allStrapiInitiative
  const areaData = data.allStrapiArea
  const { setFromIdeaGallery } = useContext(Context)
  const [dataArray, setDataArray] = useState([])
  const [placeholder, setPlaceholder] = useState(initialPlaceholder)
  const [area, setArea] = useState(null)
  const [filtaredArea, setFiltaredArea] = useState([])

  useEffect(() => {
    setFromIdeaGallery(false)
  }, [])

  useEffect(() => {
    if (area) {
      const filtaredAreaArray = initiativesData.nodes.filter(item => {
        return item.area_en === area
      })
      if (area === "Haskovo") {
        setFiltaredArea(
          filtaredAreaArray.sort((a, b) =>
            a.haeding.data.haeding.localeCompare(b.haeding.data.haeding)
          )
        )
      } else {
        setFiltaredArea(filtaredAreaArray)
      }
    }
  }, [area])

  return null
  return (
    <Layout>
      <section
        // style={{
        //   background: `url(${image}) no-repeat center/cover`,
        //   // opacity: "0.7",
        // }}
        className={`${Style.initiativeMianWrapper} main-container`}
      >
        <div className={Style.dropDownWrapper}>
          <h2>
            За да започнете да гласувате, <br /> моля първо да изберете наслено
            място{" "}
          </h2>
          <DropDown
            areaData={areaData}
            placeholder={placeholder}
            setPlaceholder={setPlaceholder}
            setArea={setArea}
          />
        </div>
        {filtaredArea.length === 0 &&
          placeholder !== "Изберете населено място" && (
            <div className={Style.noInitiativesWrapper}>
              <div className={Style.noInitiatives}>
                Няма инициативи за това населено място
              </div>
            </div>
          )}
        {filtaredArea.length !== 0 && (
          <div className={Style.initiativesWrapper}>
            {filtaredArea.map(item => {
              return (
                <div key={item.id} className={Style.innerWrapper}>
                  <InitiativeListItem data={item} />
                </div>
              )
            })}
          </div>
        )}
      </section>
    </Layout>
  )
}

export default Initiatives
